import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"
import DriftMiddleware from "@/middleware/DriftMiddleware"
import * as Pages from "@/pages"
import { AuthMiddleware, ForgotPassword, PermissionMiddleware, ResetPassword } from "@syntax51/app-kit"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    beforeEnter(to, from, next) {
      let redirectTo = "auth.login"
      const isAuthenticated = store.getters["auth/authenticated"]

      if (isAuthenticated) {
        const user = store.getters["auth/user"]

        if (!user.permissions["search.destination"]) {
          redirectTo = "collections"
        } else {
          redirectTo = "dashboard"
        }
      }

      next({ name: redirectTo, replace: true })
    }
  },
  {
    name: "auth.login",
    path: "/login",
    component: Pages.Login,
    meta: { auth: false, guestOnly: true }
  },
  {
    name: "auth.forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
    meta: { auth: false, guestOnly: true }
  },
  {
    name: "auth.reset-password",
    path: "/reset-password/:token?",
    component: ResetPassword,
    meta: { auth: false, guestOnly: true }
  },
  {
    name: "auth.logout",
    path: "/logout",
    async beforeEnter(to, from, next) {
      await store.dispatch("auth/logout")
      next({ name: "auth.login" })
    }
  },
  {
    name: "analytics",
    path: "/analytics",
    component: Pages.Analytics,
    meta: { requiresPermission: "analytics.reporting" },
    redirect: { name: "trade-analytics" },
    children: [
      {
        name: "content-analytics",
        path: "content",
        component: Pages.ContentAnalytics,
        meta: { requiresPermission: "analytics.reporting" }
      },
      {
        name: "trade-analytics",
        path: "trade",
        component: Pages.TradeAnalytics,
        meta: { requiresPermission: "analytics.reporting" }
      },
      {
        name: "inspires-user-analytics",
        path: "trade/advisors/:id",
        component: Pages.InspiresUserAnalytics,
        meta: { requiresPermission: "analytics.reporting" }
      },
      {
        name: "extended-collection-analytics",
        path: "trade/collections/:id",
        component: Pages.ExtendedCollectionAnalytics,
        meta: { requiresPermission: "analytics.reporting" }
      }
    ]
  },
  {
    name: "collection.folder",
    path: "/collections/folders/:id",
    component: Pages.CollectionFolder
  },
  {
    name: "collection",
    path: "/collections/:id",
    component: Pages.Collection,
    async beforeEnter(to, from, next) {
      await store.dispatch("collection/find", to.params.id)
      next()
    }
  },
  {
    name: "collections",
    path: "/collections",
    component: Pages.CollectionFolder
  },
  {
    name: "content.new",
    path: "/content/new",
    component: Pages.AddEditContent,
    meta: { requiresPermission: "content.private_manage" }
  },
  {
    name: "content.edit",
    path: "/content/:id/edit",
    component: Pages.AddEditContent
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: Pages.Dashboard,
    meta: { requiresPermission: "search.destination" }
  },
  {
    name: "forms",
    path: "/forms",
    component: Pages.Forms,
    meta: { requiresPermission: "advanced.forms" }
  },
  {
    name: "settings",
    path: "/settings",
    component: Pages.Settings,
    redirect: { name: "settings.account" },
    children: [
      {
        name: "settings.account",
        path: "account",
        component: Pages.AccountSettings
      },
      {
        name: "settings.landing-page",
        path: "landing-page",
        component: Pages.LandingPageSettings
      },
      {
        name: "settings.api",
        path: "api",
        component: Pages.ApiSettings,
        meta: { requiresPermission: "sharing.api_access" }
      },
      {
        name: "settings.analytics",
        path: "analytics",
        component: Pages.AnalyticsSettings,
        meta: { requiresPermission: "analytics.manage_settings" }
      },
      {
        name: "settings.security",
        path: "security",
        component: Pages.SecuritySettings
      }
    ]
  },
  {
    path: "/content",
    component: Pages.Content,
    meta: { requiresPermission: "analytics.reporting" },
    redirect: { name: "content" },
    children: [
      {
        name: "content",
        path: "exclusive",
        component: Pages.ExclusiveContent,
        meta: { requiresPermission: "content.private_manage" }
      },
      {
        name: "user-uploads",
        path: "user-uploads",
        component: Pages.UserUploads
      }
    ]
  },
  {
    name: "404",
    path: "/404",
    component: Pages.PageNotFound,
    meta: { auth: false }
  },
  {
    name: "catch-all",
    path: "*",
    redirect: { name: "404" }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(AuthMiddleware)
router.beforeEach(PermissionMiddleware)
router.beforeEach(DriftMiddleware)

export default router
