module.exports = {
  appName: "Approach Guides",
  apiPrefix: "go",
  titleTemplate: "%s | Approach Guides",
  formSuccessMessage: "Successfully updated!",
  copyrightText: `&copy; 2016-${new Date().getFullYear()}, Approach Guides.`,
  iconAlias: {
    "block-content": "photo-video",
    "block-cta": "megaphone",
    "block-form": "square-check",
    "block-heading": "heading",
    "block-image": "image",
    "block-itinerary": "list",
    "block-note": "sticky-note",
    "block-text": "text",
    "block-unknown": "question",
    cart: "shopping-cart",
    collection: "bullseye",
    content: "photo-video",
    contentDepth: "layer-group",
    language: "language",
    subjectArea: "thumbtack"
  }
}
